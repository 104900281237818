import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './InstagramCarousel.css';

const InstagramCarousel = () => {
  const [posts, setPosts] = useState([]);
  const accessToken = process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN;

  useEffect(() => {
    fetch(`https://graph.instagram.com/me/media?fields=id,caption,media_url,media_type&access_token=${accessToken}`)
      .then((response) => response.json())
      .then((data) => {
        setPosts(data.data || []);
      })
      .catch((error) => {
        console.error('Error fetching Instagram posts:', error);
      });
  }, [accessToken]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <div className="instagram-carousel">
      <h1>Follow Us on Instagram</h1>
      <Carousel responsive={responsive}>
        {posts.map((post) => (
          <div key={post.id} className="instagram-post">
            {post.media_type === 'VIDEO' ? (
              <video controls>
                <source src={post.media_url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img src={post.media_url} alt={post.caption} />
            )}
            <p>{post.caption}</p>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default InstagramCarousel;
